import { Box, Container } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import RadioQuestion from "../../../../components/RadioWithOther";
import DropDownCard from "../../../../components/Dropdown";
import RatingScore from "../../../../components/RatingScore";
import CheckBoxQuestion from "../../../../components/Checkbox";
import TextFieldQuestion from "../../../../components/TextField";
import TextAreaQuestion from "../../../../components/TextArea";
import { useLayout } from "../../../../context/LayoutContext";
import { SubmitHandler, useForm } from "react-hook-form";
import Title from "../../../../components/common/Title";
import {
  DangerousDiv,
  containerButtonStyle,
  containerDescStyle,
} from "../../../../components/common/Style/index";
import {
  useSubmitAnswer,
  useSubmitDraftAnswer,
} from "../../../../services/home/home-query";
import {
  SubmitAnswerBody,
  SubmitDraftAnswerBody,
} from "../../../../services/home/home-type";
import {
  clearLocal,
  getDraftAnswerSubmit,
  getFieldLang,
  getDraftId,
  getLang,
  getLatestSectionNo,
  getSurveyGroupID,
  setDraftAnswerSubmit,
  setLatestSectionNo,
  setDraftId,
  useRouter,
  resetToken,
} from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/common/SectionHeader";
import ProgressBar from "../../../../components/ProgressBar";
import SectionImage from "../../../../components/common/Image";
import { SectionListProps } from "./type";
import Loading from "../../../../components/common/Loading";
import CommonPrimaryButton from "../../../../components/common/CommonPrimaryButton";
import CommonSecondaryButton from "../../../../components/common/CommonSecondaryButton";
import { countTotalMandatoryQuestions } from "../../../../utils/question-helper";

function Section({ data }: SectionListProps) {
  const navigate = useNavigate();
  const { query } = useRouter();
  const { answerCount, setAnswerCount, isTabletSize } = useLayout();
  const [answer, setAnswer] = useState<Answer[]>(
    getDraftAnswerSubmit()?.answers || []
  );
  const [sectionNo, setSectionNo] = useState<number>(
    Number(getLatestSectionNo()) || 0
  );
  const [stateAnswer, setstateAnswer] = useState<Answer[] | null>(
    getDraftAnswerSubmit()?.answers || null
  );
  const { mutate: submitDraftAnswer } = useSubmitDraftAnswer();
  const { data: resAnswers, mutate: submitAnswer } = useSubmitAnswer();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const {
    setValue,
    handleSubmit,
    register,
    control,
    resetField,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<Answer[]>();
  const [checkError, setCheckError] = useState(false);
  const surveyGroupId = getSurveyGroupID();
  const [questionSectionNo, setQuestionSectionNo] = useState<number>(0);
  const lang = useMemo(() => {
    return getLang() || "en";
  }, []);
  useEffect(() => {
    if (getLatestSectionNo()) {
      let countAnswer = 0;
      for (let index = 0; index <= Number(getLatestSectionNo()); index++) {
        const arrCountAnswer: number[] = [];
        data[index].questions.forEach((q) => {
          if (countAnswer <= answer.length) {
            if (q.is_required) {
              arrCountAnswer.push(q.id);
            }
            countAnswer++;
          }
        });
        setAnswerCount((prev) => [...prev, arrCountAnswer]);
      }
    } else {
      setAnswerCount([[]]);
    }
  }, []);

  const sectionQuestion = useMemo(() => {
    setIsLoading(true);
    let countQuestion = 0;
    for (let index = 0; index < sectionNo; index++) {
      const countDeactivateQusetion = data[index].questions.filter(
        (questions) => questions.deactivate === false
      );
      countQuestion += countDeactivateQusetion.length;
    }
    setQuestionSectionNo(countQuestion);
    return data[sectionNo].questions.filter(
      (questions) => questions.deactivate === false
    );
  }, [sectionNo]);

  const section = data[sectionNo];

  useEffect(() => {
    if (getDraftAnswerSubmit()) {
      if (section && getDraftAnswerSubmit()) {
        setIsLoading(false);
      }
    } else {
      if (section) {
        setIsLoading(false);
      }
    }
  }, [section, setIsLoading, stateAnswer]);

  useEffect(() => {
    setTimeout(function () {
      setButtonLoading(false);
    }, 2000);
  }, [buttonLoading]);

  const handleBackSection = () => {
    if (sectionNo > 0) {
      if (answerCount.length === sectionNo + 1) {
        setAnswerCount((prev) => [...prev.slice(0, -1), []]);
      }
      setSectionNo(sectionNo - 1);
    }
    reset();
  };

  const isLastSection = () => {
    return sectionNo === data.length - 1;
  };

  const onSubmit: SubmitHandler<Answer[]> = (submittedData) => {
    if (sectionNo === data.length - 1) {
      setButtonLoading(true);
    }
    const result = Object.entries(submittedData).map(([key, value]) => {
      if (!value) {
        return {
          q_id: +key + 1 || null,
          opt_id: null,
          opt_ids: null,
          opt_short_text: null,
          opt_long_text: null,
          opt_others: null,
        };
      }
      return {
        q_id: +key + 1 || null,
        opt_id: Number(value.opt_id) || null,
        opt_ids: value.opt_ids?.sort() || null,
        opt_short_text: value.opt_short_text || null,
        opt_long_text: value.opt_long_text?.trimEnd() || null,
        opt_others: value.opt_others || null,
      };
    });
    const payload = {
      survey_group_id: Number(query?.survey_group_id) || Number(surveyGroupId),
      channel: "aster",
      lang: lang,
      id: getDraftId() === "null" ? "" : getDraftId(),
      consents: [
        {
          consent_id: 1,
          allowed: true,
        },
      ],
      answers: [
        ...answer.filter((ans) => !result.some((res) => res.q_id === ans.q_id)),
        ...result,
      ],
    } as SubmitAnswerBody;
    const payloadDraftSubmit = {
      survey_group_id: Number(query?.survey_group_id) || Number(surveyGroupId),
      channel: "aster",
      lang: lang,
      id: getDraftId() === "null" ? "" : getDraftId(),
      consents: [
        {
          consent_id: 1,
          allowed: true,
        },
      ],
      answers: [
        ...answer.filter((ans) => !result.some((res) => res.q_id === ans.q_id)),
        ...result,
      ],
      section_no: sectionNo + 1,
    } as SubmitDraftAnswerBody;
    setAnswer((prev) => [
      ...(prev.filter(
        (ans) => !result.some((res) => res.q_id === ans.q_id)
      ) as Answer[]),
      ...(result as Answer[]),
    ]);
    payloadDraftSubmit.answers = payloadDraftSubmit.answers.sort(
      (a, b) => a.q_id - b.q_id
    );
    payload.answers = payload.answers.sort((a, b) => a.q_id - b.q_id);
    setDraftAnswerSubmit(payloadDraftSubmit);
    if (!isLastSection()) {
      submitDraftAnswer(payloadDraftSubmit, {
        onSuccess(data) {
          const result = data.data as any;
          setDraftId(result.id);
        },
      });
      setSectionNo(sectionNo + 1);
      if (
        answerCount.length < data.length &&
        answerCount.length <= sectionNo + 1
      ) {
        setAnswerCount((prev) => [...prev, []]);
      }
    } else {
      submitAnswer(payload);
    }
    setstateAnswer(getDraftAnswerSubmit().answers);
    setLatestSectionNo(sectionNo);
    reset();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [sectionNo]);

  useEffect(() => {
    if (resAnswers && resAnswers.code === 0) {
      clearLocal();
      resetToken();
      navigate("/finish");
    }
  }, [resAnswers, submitAnswer]);

  useEffect(() => {
    setTimeout(() => {
      const errArray = Object.entries(errors);
      for (const err of errArray) {
        const elements = document.querySelectorAll(`[name="${err[0]}"]`);
        if (elements.length > 0) {
          elements[0].scrollIntoView?.({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        break;
      }
    }, 50);
  }, [errors, checkError]);
  const getQuestionTemplate = (item: Question, index: number) => {
    const questionId = item.id || index;
    const questionTitle = getFieldLang(item, "title", lang);
    const questinDescription = getFieldLang(item, "description", lang);
    const questionOptions = item.options;
    const questionOptionsOthers = item.options_others;
    const isQuestionRequired = item.is_required;
    const ratingIndicators = item.rating_indicators;
    const questionNo = questionSectionNo + index + 1;
    switch (item?.type) {
      case "textbox":
        return (
          <TextFieldQuestion
            key={index}
            qid={questionId}
            questionTitle={questionTitle}
            questionDescription={questinDescription}
            required={isQuestionRequired}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            register={register}
            errors={errors}
            section={sectionNo}
            resetField={resetField}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      case "textarea":
        return (
          <TextAreaQuestion
            key={index}
            qid={questionId}
            required={isQuestionRequired}
            questionTitle={questionTitle}
            textAreaPlaceholder={questionTitle}
            textAreaRows={2}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            register={register}
            errors={errors}
            section={sectionNo}
            resetField={resetField}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      case "radio":
        return (
          <RadioQuestion
            key={index}
            questionTitle={questionTitle}
            questionDescription={questinDescription}
            questionOption={questionOptions!}
            questionImage={undefined}
            required={isQuestionRequired}
            qid={questionId}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            control={control}
            resetField={resetField}
            errors={errors}
            questionOptionsOthers={questionOptionsOthers}
            section={sectionNo}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      case "checkbox":
        return (
          <CheckBoxQuestion
            key={index}
            questionTitle={questionTitle}
            questionDescription={questinDescription}
            questionOption={questionOptions!}
            questionImage={undefined}
            required={isQuestionRequired}
            qid={questionId}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            control={control}
            resetField={resetField}
            getValues={getValues}
            errors={errors}
            questionOptionsOthers={questionOptionsOthers}
            section={sectionNo}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      case "dropdown":
        return (
          <DropDownCard
            key={index}
            qid={questionId}
            required={isQuestionRequired}
            questionTitle={questionTitle}
            description={questinDescription}
            questionImage={""}
            inputLabel={questionTitle}
            dropdownItems={questionOptions}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            register={register}
            errors={errors}
            section={sectionNo}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      case "rating_choice":
        return (
          <RatingScore
            key={index}
            qid={questionId}
            required={isQuestionRequired}
            imageRequired={false}
            questionTitle={questionTitle}
            description={questinDescription}
            questionImage={""}
            questionOption={questionOptions}
            ratingIndicators={ratingIndicators}
            setValueAnswer={setValue}
            stateAnswer={stateAnswer?.find((ans) => ans.q_id === questionId)}
            control={control}
            errors={errors}
            section={sectionNo}
            trigger={trigger}
            questionNo={questionNo}
          />
        );
      default:
        return null;
    }
  };

  return isLoading ? (
    <Box sx={{ left: "50%", position: "absolute", top: "50%" }}>
      <Loading loading={isLoading} />
    </Box>
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isTabletSize ? (
          <>
            <ProgressBar
              currentQuestion={
                answerCount[sectionNo] ? answerCount[sectionNo].length : 0
              }
              totalQuestions={countTotalMandatoryQuestions(sectionQuestion)}
              isSection={true}
              currentSection={sectionNo + 1}
              totalSection={data.length}
            />
            <Header text={getFieldLang(section, "header", lang)} />
          </>
        ) : (
          <Header text={getFieldLang(section, "header", lang)}>
            <ProgressBar
              currentQuestion={
                answerCount[sectionNo] ? answerCount[sectionNo].length : 0
              }
              totalQuestions={countTotalMandatoryQuestions(sectionQuestion)}
              isSection={true}
              currentSection={sectionNo + 1}
              totalSection={data.length}
            />
          </Header>
        )}
        <Title title={getFieldLang(section, "title", lang)} />
        <Container sx={containerDescStyle}>
          <DangerousDiv
            dangerouslySetInnerHTML={{
              __html: getFieldLang(section, "description", lang),
            }}
          />
        </Container>

        {/* {false && ( //wait to section image
          <SectionImage image="" />
        )} */}
        <Box>
          {sectionQuestion.map((question: Question, index) => {
            return getQuestionTemplate(question, index);
          })}
          <Container sx={containerButtonStyle}>
            {sectionNo !== 0 && (
              <CommonSecondaryButton
                onClick={handleBackSection}
                width="160px"
                margin="0px 24px 0px 0px"
              >
                Back
              </CommonSecondaryButton>
            )}
            {sectionNo === data.length - 1 ? (
              <CommonPrimaryButton
                type="submit"
                width="160px"
                onClick={() => setCheckError(!checkError)}
              >
                Finish
              </CommonPrimaryButton>
            ) : (
              <CommonPrimaryButton
                type="submit"
                width="160px"
                onClick={() => setCheckError(!checkError)}
              >
                Next
              </CommonPrimaryButton>
            )}
          </Container>
        </Box>
      </form>
    </>
  );
}
export default Section;
