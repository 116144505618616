import {
  Box,
  Container,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useLayout } from "../../context/LayoutContext";
import {
  containerStyle,
  OptionalQuestionStyle,
  QuestionTitleText,
  QuestionDescriptionText,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import { InputLabelDropdown, dropdownMenuStyle, dropdownStyle } from "./style";
import { QuestionImages } from "../common/Image";
import { DropdownProps } from "./type";
import ArrowDown from "../../constants/icons/ic_arrow_down.svg";

const DropDownCard = ({
  qid,
  required,
  questionTitle,
  description,
  questionImage,
  inputLabel,
  dropdownItems,
  setValueAnswer,
  section,
  stateAnswer,
  register,
  errors,
  trigger,
  questionNo,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(stateAnswer?.opt_id);
  const { setAnswerCount } = useLayout();

  const setAnswerCountDropdown = (required: boolean) => {
    if (required) {
      setAnswerCount((prev) => {
        const updatedCount = [...prev];
        if (!updatedCount[section].includes(qid)) {
          updatedCount[section].push(qid);
        }
        return updatedCount;
      });
    }
  };

  useMemo(() => {
    if (stateAnswer?.opt_id) {
      setAnswerCountDropdown(required);
    }
  }, []);

  const handleChange = (event: SelectChangeEvent<any>) => {
    setSelectedValue(event.target.value);
    setValueAnswer(`${qid! - 1}`, {
      q_id: qid!,
      opt_id: event.target.value,
      opt_ids: null,
      opt_short_text: null,
      opt_long_text: null,
      opt_others: null,
    });
    setAnswerCountDropdown(required);
    trigger(`${qid! - 1}`);
  };
  const activeQuestions = useMemo(() => {
    const dataQuestions = dropdownItems!.filter(
      (options) => options.deactivate === false
    );
    return dataQuestions;
  }, []);
  return (
    <>
      <Container sx={containerStyle} id={`${qid!}`}>
        <Box sx={{ textAlign: "left" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DangerousDivTiTle
              dangerouslySetInnerHTML={{
                __html: `${questionNo}. ${questionTitle}`,
              }}
            />
            {!required && (
              <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
            )}
          </Box>
          <QuestionDescriptionText>{description}</QuestionDescriptionText>
          {questionImage && <QuestionImages image={questionImage} />}

          <FormControl sx={dropdownStyle} fullWidth>
            <InputLabelDropdown id="dropdownLabel">
              Choose your answer
            </InputLabelDropdown>
            <Select
              IconComponent={(props) => (
                <img
                  src={ArrowDown}
                  style={{
                    width: "14px",
                    height: "7px",
                    marginRight: "13px",
                    pointerEvents: "none",
                    top: "unset",
                  }}
                  {...props}
                />
              )}
              labelId="dropdownLabel"
              value={selectedValue}
              label={"Choose your answer"}
              {...register(`${qid! - 1}.opt_id`, {
                required: required,
                onChange: handleChange,
              })}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "10px",
                    color: "#6e7a91",
                    backgroundColor: "#1f2126",
                    zIndex: 10000,
                    maxHeight: 200,
                    overflowY: "auto",
                    borderRadius: "10px",
                    overflowX: "auto",
                  },
                },
              }}
            >
              {activeQuestions?.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.id}
                  sx={dropdownMenuStyle}
                >
                  {item.label_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors[qid! - 1] &&
            errors[qid! - 1]?.opt_id?.type === "required" && (
              <InlineRequireText>This is required</InlineRequireText>
            )}
        </Box>
      </Container>
    </>
  );
};

export default DropDownCard;
