import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  containerStyle,
  OptionalQuestionStyle,
  QuestionDescriptionText,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import {
  radioLabelStyle,
  radioLabelOtherStyle,
  radioOtherTextFieldStyle,
  radioGroupStyle,
  RadioAster,
  RadioAsterOther,
} from "./style";
import { useEffect, useState, useMemo, ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { useLayout } from "../../context/LayoutContext";
import { QuestionImages } from "../common/Image";
import { RadioProps } from "./type";

const RadioQuestion = ({
  questionTitle,
  qid,
  questionOption,
  required,
  questionImage,
  questionDescription,
  setValueAnswer,
  stateAnswer,
  control,
  errors,
  questionOptionsOthers,
  section,
  trigger,
  questionNo,
}: RadioProps) => {
  const { setAnswerCount } = useLayout();
  const [selectedOption, setSelectedOption] = useState(
    questionOptionsOthers && stateAnswer?.opt_others
      ? "others"
      : String(stateAnswer?.opt_id)
  );
  const [otherText, setOtherText] = useState(
    stateAnswer?.opt_others?.[0] || ""
  );

  const handleRadioChangeOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (!!otherText) {
      handleRadioChangeValue(otherText);
    }
  }, [otherText]);

  const setAnswerCountRadio = (required: boolean, answer: string | number) => {
    if (required) {
      setAnswerCount((prev) => {
        const updatedCount = [...prev];
        if (answer.toString().trim().length) {
          if (!updatedCount[section].includes(qid)) {
            updatedCount[section].push(qid);
          }
        } else {
          updatedCount[section] = updatedCount[section].filter(
            (id) => id !== qid
          );
        }
        return updatedCount;
      });
    }
  };

  useMemo(() => {
    if (stateAnswer?.opt_id || stateAnswer?.opt_others) {
      if (stateAnswer?.opt_id) {
        setAnswerCountRadio(required, stateAnswer?.opt_id);
      }
      if (stateAnswer?.opt_others) {
        setAnswerCountRadio(required, stateAnswer?.opt_others[0]);
      }
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: stateAnswer?.opt_id || null,
        opt_ids: null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others: stateAnswer?.opt_others || null,
      });
    }
  }, []);

  const handleRadioChangeValue = (value: number | string) => {
    if (typeof value === "number") {
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: value || null,
        opt_ids: null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others: null,
      });
    } else {
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid!,
        opt_id: null,
        opt_ids: null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others:
          typeof value === "string" &&
          value.trim().length > 0 &&
          value !== "others"
            ? [value.trim()]
            : null,
      });
    }
    trigger(`${qid! - 1}`);
  };
  const activeQuestions = useMemo(() => {
    const dataQuestions = questionOption.filter(
      (options) => options.deactivate === false
    );
    return dataQuestions;
  }, []);

  return (
    <Container sx={containerStyle} id={`${qid}`}>
      <Box>
        <Box sx={{ textAlign: "start" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DangerousDivTiTle
              dangerouslySetInnerHTML={{
                __html: `${questionNo}. ${questionTitle}`,
              }}
            />
            {!required && (
              <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
            )}
          </Box>
          <QuestionDescriptionText>
            {questionDescription}
          </QuestionDescriptionText>

          {questionImage && <QuestionImages image={questionImage} />}
        </Box>
        <Box sx={{ textAlign: "start", marginTop: "20px" }}>
          <FormControl sx={{ width: "100%", zIndex: 0 }}>
            <Controller
              control={control}
              name={`${qid! - 1}`}
              rules={{ required: required }}
              render={() => {
                return (
                  <RadioGroup
                    value={selectedOption}
                    defaultValue={stateAnswer?.opt_id}
                    onChange={handleRadioChangeOption}
                    name={`${qid! - 1}`}
                    sx={radioGroupStyle}
                  >
                    {activeQuestions!.map((item, index) => (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        key={index}
                      >
                        <FormControlLabel
                          sx={radioLabelStyle(+selectedOption, item.id)}
                          value={item.id}
                          control={
                            <RadioAster
                              name={`${qid! - 1}`}
                              onClick={() => {
                                setAnswerCountRadio(required, item.id);
                                handleRadioChangeValue(item.id);
                              }}
                            />
                          }
                          label={item.label_en}
                        />
                      </div>
                    ))}
                    {questionOptionsOthers?.map((item, index) => (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        key={qid! - 1}
                      >
                        <FormControlLabel
                          sx={radioLabelOtherStyle(
                            selectedOption,
                            item.label_en
                          )}
                          value={item.value}
                          control={
                            <RadioAsterOther
                              name={`${qid! - 1}`}
                              onClick={() => {
                                handleRadioChangeValue(item.value);
                              }}
                            />
                          }
                          label={
                            <div
                              style={{ boxSizing: "border-box", width: "100%" }}
                            >
                              <Typography>{item.label_en}</Typography>
                              <TextField
                                fullWidth
                                defaultValue={stateAnswer?.opt_others}
                                name={`${qid! - 1}`}
                                placeholder="please specific reason"
                                sx={radioOtherTextFieldStyle}
                                onChange={(e) => {
                                  const answer = e.target.value;
                                  handleRadioChangeValue(answer);
                                  setOtherText(answer);
                                  setAnswerCountRadio(required, answer);
                                }}
                                onMouseDown={() => {
                                  setSelectedOption("others");
                                  handleRadioChangeValue(item.value);
                                }}
                                variant="outlined"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
          {errors[qid - 1] && errors[qid - 1]?.type === "required" && (
            <InlineRequireText>This is required</InlineRequireText>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default RadioQuestion;
