import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  containerStyle,
  OptionalQuestionStyle,
  QuestionTitleText,
  QuestionDescriptionText,
  InlineRequireText,
  DangerousDivTiTle,
} from "../common/Style";
import {
  BoxStyle,
  CheckBoxAster,
  CheckBoxAsterOther,
  checkboxOtherTextFieldStyle,
  checkboxLabelOtherStyle,
  formControlLabelStyle,
  radioGroupStyle,
} from "./style";
import { useLayout } from "../../context/LayoutContext";
import { QuestionImages } from "../common/Image";
import { CheckBoxProps } from "./type";

const CheckBoxQuestion = ({
  questionTitle,
  qid,
  questionOption,
  required,
  questionImage,
  questionDescription,
  setValueAnswer,
  stateAnswer,
  control,
  getValues,
  errors,
  resetField,
  questionOptionsOthers,
  section,
  trigger,
  questionNo,
}: CheckBoxProps) => {
  const [isHasText, setIsHasText] = useState(
    stateAnswer?.opt_others ? true : false
  );
  const [otherText, setOtherText] = useState(
    stateAnswer?.opt_others?.[0] || ""
  );
  const [checkedBoxs, setCheckedBoxs] = useState<(number | string)[]>([
    ...(stateAnswer?.opt_ids ?? []),
    ...((stateAnswer?.opt_others?.[0] ? [stateAnswer.opt_others[0]] : []) ||
      []),
  ]);
  const { setAnswerCount } = useLayout();

  useEffect(() => {
    if (!!otherText) {
      handleCheckboxChange(otherText, true);
    }
  }, [otherText]);

  const setAnswerCountCheckbox = (
    required: boolean,
    value: string | number,
    isChecked?: boolean
  ) => {
    if (required) {
      if (isChecked) {
        if (typeof value == "number") {
          setAnswerCount((prev) => {
            const updatedCount = [...prev];
            if (!updatedCount[section].includes(qid)) {
              updatedCount[section].push(qid);
            }
            return updatedCount;
          });
        }
        if (value === "others" && otherText.length) {
          setAnswerCount((prev) => {
            const updatedCount = [...prev];
            if (!updatedCount[section].includes(qid)) {
              updatedCount[section].push(qid);
            }
            return updatedCount;
          });
        }
        if (otherText.length) {
          setAnswerCount((prev) => {
            const updatedCount = [...prev];
            if (!updatedCount[section].includes(qid)) {
              updatedCount[section].push(qid);
            }
            return updatedCount;
          });
        }
        if (
          !getValues(`${qid! - 1}.opt_ids`) &&
          !getValues(`${qid! - 1}.opt_others` || value === "")
        ) {
          setAnswerCount((prev) => {
            const updatedCount = [...prev];
            updatedCount[section] = updatedCount[section].filter(
              (id) => id !== qid
            );
            return updatedCount;
          });
        }
      } else if (
        !getValues(`${qid! - 1}.opt_ids`) &&
        !getValues(`${qid! - 1}.opt_others` || value === "")
      ) {
        setAnswerCount((prev) => {
          const updatedCount = [...prev];
          updatedCount[section] = updatedCount[section].filter(
            (id) => id !== qid
          );
          return updatedCount;
        });
      }
    }
  };

  useEffect(() => {
    if (stateAnswer?.opt_ids) {
      setAnswerCountCheckbox(required, 1, true);
    }
    if (stateAnswer?.opt_others) {
      setAnswerCountCheckbox(required, "123", true);
    }
  }, []);

  useMemo(() => {
    if (stateAnswer?.opt_ids || stateAnswer?.opt_others) {
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid,
        opt_id: null,
        opt_ids: stateAnswer?.opt_ids || null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others: stateAnswer?.opt_others || null,
      });
    }
  }, [stateAnswer]);

  const handleCheckboxChange = (value: string | number, isChecked: boolean) => {
    if (isChecked) {
      setCheckedBoxs((prev) => [...prev, value]);
      if (value !== "others" && value !== "") {
        setValueAnswer(`${qid! - 1}`, {
          q_id: qid,
          opt_id: null,
          opt_ids:
            typeof value === "number"
              ? [...(getValues(`${qid! - 1}.opt_ids`) || []), value]
              : getValues(`${qid! - 1}.opt_ids`) || null,
          opt_short_text: null,
          opt_long_text: null,
          opt_others:
            typeof value === "string" && value.trim() !== "" && isHasText
              ? [value.trim()]
              : getValues(`${qid! - 1}.opt_others`) || null,
        });
      } else if (
        value === "" &&
        (!getValues(`${qid! - 1}.opt_ids`) ||
          getValues(`${qid! - 1}.opt_ids`)?.length === 0)
      ) {
        resetField(`${qid! - 1}`);
      } else if (value === "") {
        setValueAnswer(`${qid! - 1}`, {
          q_id: qid,
          opt_id: null,
          opt_ids:
            typeof value === "number"
              ? [...(getValues(`${qid! - 1}.opt_ids`) || []), value]
              : getValues(`${qid! - 1}.opt_ids`) || null,
          opt_short_text: null,
          opt_long_text: null,
          opt_others: null,
        });
      } else if (otherText.length > 0) {
        setValueAnswer(`${qid! - 1}`, {
          q_id: qid,
          opt_id: null,
          opt_ids:
            typeof value === "number"
              ? [...(getValues(`${qid! - 1}.opt_ids`) || []), value]
              : getValues(`${qid! - 1}.opt_ids`) || null,
          opt_short_text: null,
          opt_long_text: null,
          opt_others:
            otherText.trim() === "" && !isHasText ? null : [otherText.trim()],
        });
      }
      // setAnswerCountCheckbox(required, value);
    } else {
      const filteredOptIds = getValues(`${qid! - 1}.opt_ids`)?.filter(
        (optId) => optId !== value
      );
      const optOthers = getValues(`${qid! - 1}.opt_others`)?.filter(
        () => value !== "others"
      );
      setCheckedBoxs((prev) => {
        return prev.filter((item) => value !== item);
      });
      setValueAnswer(`${qid! - 1}`, {
        q_id: qid,
        opt_id: null,
        opt_ids:
          filteredOptIds && filteredOptIds.length > 0 ? filteredOptIds : null,
        opt_short_text: null,
        opt_long_text: null,
        opt_others: optOthers && optOthers.length > 0 ? optOthers : null,
      });
      if (
        (!filteredOptIds || filteredOptIds?.length === 0) &&
        (!optOthers || optOthers?.length === 0)
      ) {
        resetField(`${qid! - 1}`);
      }
    }
    setAnswerCountCheckbox(required, value, isChecked);
    trigger(`${qid! - 1}`);
  };
  const activeQuestions = useMemo(() => {
    const dataQuestions = questionOption.filter(
      (options) => options.deactivate === false
    );
    return dataQuestions;
  }, []);

  return (
    <Container sx={containerStyle} id={`${qid}`}>
      <Box sx={{ padding: 0 }}>
        <Box>
          <Box sx={{ textAlign: "start" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DangerousDivTiTle
                dangerouslySetInnerHTML={{
                  __html: `${questionNo}. ${questionTitle}`,
                }}
              />
              {!required && (
                <OptionalQuestionStyle> (optional)</OptionalQuestionStyle>
              )}
            </Box>
            <QuestionDescriptionText>
              {questionDescription}
            </QuestionDescriptionText>
            {questionImage && <QuestionImages image={questionImage} />}
          </Box>
          <BoxStyle>
            <FormControl sx={{ width: "100%", zIndex: 0 }}>
              <Controller
                control={control}
                name={`${qid! - 1}`}
                rules={{ required: required }}
                render={() => {
                  return (
                    <RadioGroup name={`${qid! - 1}`} sx={radioGroupStyle}>
                      {activeQuestions?.map((item, index) => (
                        <FormControlLabel
                          sx={formControlLabelStyle(
                            checkedBoxs.includes(item.id)
                          )}
                          key={item.id}
                          value={item.id}
                          control={
                            <CheckBoxAster
                              name={`${qid! - 1}`}
                              defaultChecked={(
                                stateAnswer?.opt_ids ?? []
                              ).includes(item.id)}
                              onChange={(e) => {
                                handleCheckboxChange(item.id, e.target.checked);
                              }}
                            />
                          }
                          label={item.label_th}
                        />
                      ))}
                    </RadioGroup>
                  );
                }}
              />
              {questionOptionsOthers?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "16px",
                  }}
                  key={qid! - 1}
                >
                  <FormControlLabel
                    key={item.id}
                    sx={checkboxLabelOtherStyle(
                      checkedBoxs.includes(item.value) || isHasText
                    )}
                    value={item.value}
                    control={
                      <CheckBoxAsterOther
                        checked={isHasText}
                        name={`${qid! - 1}`}
                        onChange={(e) => {
                          handleCheckboxChange(item.value, e.target.checked);
                          setIsHasText(!isHasText);
                        }}
                      />
                    }
                    label={
                      <div style={{ boxSizing: "border-box", width: "100%" }}>
                        <Typography>{item.label_en}</Typography>
                        <TextField
                          fullWidth
                          defaultValue={stateAnswer?.opt_others}
                          name={`${qid! - 1}`}
                          placeholder="please specific reason"
                          sx={checkboxOtherTextFieldStyle}
                          onChange={(e) => {
                            const answer = e.target.value;
                            handleCheckboxChange(answer, true);
                            setOtherText(answer);
                          }}
                          onMouseDown={() => {
                            setIsHasText(true);
                            handleCheckboxChange(item.value, true);
                          }}
                          variant="outlined"
                          InputProps={{
                            disableUnderline: true,
                            style: { color: "#fff" },
                          }}
                        />
                      </div>
                    }
                  />
                </div>
              ))}
            </FormControl>
            {errors[qid! - 1] && errors[qid! - 1]?.type === "required" && (
              <InlineRequireText>This is required</InlineRequireText>
            )}
          </BoxStyle>
        </Box>
      </Box>
    </Container>
  );
};

export default CheckBoxQuestion;
